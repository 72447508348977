<script setup></script>

<template>
  <div id="app" class="primary-bg primary-text">
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
